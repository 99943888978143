import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const hidden_beach_at_marietas_islands = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'HIDDEN BEACH AT MARIETAS ISLANDS | HB Privileged',
      title: 'HIDDEN BEACH AT MARIETAS ISLANDS',
      subtitle: 'TOUR OVERVIEW',
      text: "Get on board our sailing boat with Pegaso, a very traditional enterprise, in Nuevo Vallarta towards the mysterious Marietas Islands. Take advantage and get to know the Hotel Zone of Nayarit and all the flora and fauna our neighbor state has to offer. Enjoy a Continental Breakfast on board this extremely comfortable vessel waited by an excellent staff. Sail to the Islands without the nose of an engine and with the melody of the waves and the wind blowing on the sails. In less than a couple of hours we ́ll be arriving to the complex that harbors one of the most beautiful beaches in the world according to several publications. Only expert swimmers can access to it, because you have to swim through a tunnel almost 300 ft long, but once on the beach you have a jaw dropping view. Hidden Beach is probably the most famous beach in Mexico in photographs but only a few lucky have access to it and you can be one of them. Coming back, enjoy the open bar on board and an exquisit lunch. Water sports like snorkeling, kayaking and paddle boarding are also included. Make a reservation.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'PLAYA ESCONDIDA EN ISLAS MARIETAS | HB Privileged',
      title: 'PLAYA ESCONDIDA EN ISLAS MARIETAS',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Aborde nuestro Velero con la compañía Pegaso, una empresa muy tradicional,en Nuevo Vallarta rumbo a las misteriosas Islas Marietas. Aproveche para conocer la Zona Hotelera de Nayarit y toda la flora y fauna que nuestro Estado vecino ofrece. Disfrute de un desayuno continental abordo de esta embarcación extremadamente cómoda y atendida por un excelente staff. Navegue rumbo a las Islas sin el ruido de los motores y con la melodía de las olas y del viento soplando las velas. En menos un par de horas estaremos arribando al complejo que alberga a una de las playas más bonitas del mundo según varias publicaciones. Solo nadadores expertos pueden acceder aella pues hay que nadar a través de un túnel de casi 100 metros de largo, pero una vez en la playa la naturaleza lo dejará boquiabierto. Playa Escondida es quizá la más famosa de México en fotografías pero solo unos cuántos han accedido a ella y tu puedes ser uno de ellos. Al regresar, difruta de la barra libre a bordo y de un exquisito lunch. La actividades acuáticas como snorkeling, kayaking y paddle boarding también están incluídas. Reserva,',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/hidden-beach-at-marietas-islands1.jpg`,
    `${BASE_URL}/tours/hidden-beach-at-marietas-islands2.jpg`,
    `${BASE_URL}/tours/hidden-beach-at-marietas-islands3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/hidden-beach-at-marietas-islands.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default hidden_beach_at_marietas_islands;